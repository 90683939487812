<template>
  <div class="view pa24">
    <div class="mb20">
      <span>自动审核</span>
      <el-switch
        @change="auditOnFun"
        class="ml10"
        v-model="switchValue"
        active-text="开"
        inactive-text="关"
      />
      <p class="mt10">
        提示：开启自动审核后，用户提交了分销员申请，系统会自动审核通过申请
      </p>
    </div>
    <el-button type="primary" @click="addDistributors()">添加分销员</el-button><br><br>
    选择时间：
    <el-date-picker
      class="ml10 mr10"
      value-format="yyyy-MM-dd"
      v-model="pickerValue"
      align="right"
      placeholder="选择时间"
    />
    申请人姓名：
    <el-input v-model="nickeName" class="w220 mr10" placeholder="请输入申请人姓名" />
    邀请人姓名：
    <el-input
      v-model="inviteUserName"
      class="w220 mr10"
      placeholder="请输入邀请人姓名"
    />
      申请状态：
      <el-radio
        class="mr10 ml10 mt10"
        style="margin: 0"
        v-model="radio"
        label=""
        border
        size="medium"
        >全部</el-radio
      >
      <el-radio
        class="mr10 mt10"
        style="margin: 0"
        v-model="radio"
        label="0"
        border
        size="medium"
        >审核中</el-radio
      >
      <el-radio
        class="mr10 mt10"
        style="margin: 0"
        v-model="radio"
        label="1"
        border
        size="medium"
        >已通过</el-radio
      >
      <el-radio
        class="mr10 mt10"
        style="margin: 0"
        v-model="radio"
        label="2"
        border
        size="medium"
        >已拒绝</el-radio
      >
      <el-button type="primary" @click="queryDistributorApp">搜索</el-button>
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column align="center" prop="nickeName" label="头像">
          <template slot-scope="scope">
            <el-image
              style="width: 36px; height: 36px"
              :src="scope.row.avatarUrl"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="nickeName" align="center" label="姓名" />
        <el-table-column prop="phone" align="center" label="联系方式" />
        <el-table-column prop="inviteUserName" align="center" label="邀请人">
          <template slot-scope="scope">
            {{ scope.row.inviteUserName ? scope.row.inviteUserName : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="appTime" align="center" label="申请时间" />
        <el-table-column align="center" prop="userType" label="用户类别">
          <template slot-scope="scope">
            <!--普通用户 1 员工 2普通用户-->
            {{
              scope.row.userType == 1
                ? "员工"
                : scope.row.userType == 2
                ? "普通用户"
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="appStatus" label="申请状态">
          <!--                    申请状态 0 提交 1通过 2 拒绝 3 已取消-->
          <template slot-scope="scope">
            {{
              scope.row.appStatus == 0
                ? "审核中"
                : scope.row.appStatus == 1
                ? "已通过"
                : scope.row.appStatus == 2
                ? "已拒绝"
                : scope.row.appStatus == 3 && "已取消"
            }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="170">
          <template slot-scope="scope">
            <div v-if="scope.row.appStatus == 0">
              <customPopconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="auditingRow(scope.row, 1)"
                icon="el-icon-info"
                icon-color="red"
                title="确认通过审核吗？">
                <el-button slot="reference"  type="text" size="small">
                  通过
                </el-button>
              </customPopconfirm>
              <el-button
                @click="auditingRow(scope.row, 2)"
                type="text"
                size="small"
                class="ml10"
              >
                拒绝
              </el-button>
            </div>

            <el-button
              type="text"
              size="small"
              @click="cancelRow(scope.row, 3)"
              v-if="scope.row.appStatus == 1"
            >
              {{ scope.row.appStatus == 1 ? "取消资格" : "---" }}
            </el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
      :title="dialogFormTitle"
      :modal-append-to-body="false"
      destroy-on-close
      width="400px"
      ref="form"
      :visible="dialogFormVisible"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item prop="desc">
          <el-input type="textarea" v-model="ruleForm.desc"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button class="btncss" @click="cancelRowData('form')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import {
  queryDistributorApp,
  cancelDistributorApp,
  verifyDistributorApp,
  updateAuditSettings,
  queryAuditSettings,
  getBusinessCardList,
  addBusinessCard
} from "@/api/distribution";
export default {
  name: "personnelApplication",
  components: {
    commonTable,
    customPopconfirm
  },
  data() {
    return {
      switchValue: true,
      radio: "",
      pickerValue: "",
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      pageSize2: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      nickeName: "",
      inviteUserName: "",
      dialogFormTitle: "请输入拒绝理由",
      dialogFormVisible: false,
      ruleForm: { desc: "" },
      rules: {
        desc: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      currentRow: {},
      appStatus: "",
      optionList: [],
        value: ''
    };
  },
  created() {
    this.queryDistributorApp();
    this.queryAudit();
    this.getBusinessCard();

  },
  methods: {
    addDistributors() {
      const h = this.$createElement;
      const that = this
      this.$msgbox({
        title: "请选择数据",
        message: h('el-select', 
          {
            props: {
              value: that.value,
              filterable: true,
            },
            ref: 'selectView',
            on: {
              change:e => {
                that.value = e
                that.$refs.selectView.value = e  // select下拉框值改变，回显到页面上
              },
            },
          },
          [
            this.optionList.map(it => {
              return h('el-option', {
                props: {
                  label: it.nickeName,
                  key: it.userId,
                  value: it.userId,
                },
              });
            })
          ]
        ),
        showCancelButton: true,
        closeOnClickModal: false,
        confirmButtonText: '确定', 
        cancelButtonText: '取消',
      }).then( _ => {
        this.addBusinessCard(this.value);
      	// 成功操作。。。。
      }).catch(msg => {
      	// 取消操作。。。。

      });
      },
      // 获取分销员列表
      async getBusinessCard() {
        let res = await getBusinessCardList({})
        if(res.code == 200) {
          let arr = res.data;
          arr.map(e => {
            e.nickeName = e.nickeName+'('+e.phone+')'
          })
          this.optionList = arr
        }
      },
      // 添加分销员
      async addBusinessCard(a) {
        let params = {
          distributorUserId: a
        }
        let res = await addBusinessCard(params);
        if(res.code == 200) {
          this.open('添加成功')
        }else {
          this.open('添加失败')
        }
      },
      open(a) {
        this.$message(`${a}`);
      },
    async queryDistributorApp() {
      let data = {
        appTime: this.pickerValue,
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        nickeName: this.nickeName,
        inviteUserName: this.inviteUserName,
        appStatus: this.radio,
      };
      try {
        this.loading = true;
        const result = await queryDistributorApp(data);
        this.loading = false;
        const {
          pageInfo: { total, list },
        } = result?.data;
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        // console.log(error);
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryDistributorApp();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryDistributorApp();
    },
    cancelRow(row) {
      this.dialogFormVisible = true;
      this.dialogFormTitle = "请输入取消理由";
      this.currentRow = row;
      this.appStatus = 3;
    },
    cancelRowData() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.currentRow.note = this.ruleForm.desc;
          this.currentRow.appStatus = this.appStatus;
          const data = this.currentRow
          try {
            this.appStatus == 3 && (await cancelDistributorApp(data));
            this.dialogFormVisible = false;
            await this.queryDistributorApp();
          } catch (error) {
            this.dialogFormVisible = false;
            console.log(error);
          }
        } else {
          console.log("error submit!!");
          console.log(this.ruleForm);
          return false;
        }
      });
    },
    async queryAudit() {
      try {
        const result = await queryAuditSettings({ xxx: "x" });
        const {
          data: { isTurnOn },
        } = result;
        this.switchValue = isTurnOn;
      } catch (error) {
        console.log(error);
      }
    },
    async auditingRow(row, type) {
      if (type == 1) {
        row.appStatus = 1;
        let data = row
        await verifyDistributorApp(data);
        await this.queryDistributorApp();
      } else {
        this.appStatus = 2;
        this.currentRow = row;
        this.dialogFormTitle = "请输入拒绝理由";
        this.dialogFormVisible = true;
      }
    },
    async auditOnFun() {
      try {
        await updateAuditSettings(
          { isTurnOn: this.switchValue },
          { showLoading: true }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>